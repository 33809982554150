import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  server_post_data,
  get_invoice_data_single,
} from "../../ServiceConnection/serviceconnection.js";
import { handleError } from "../../CommonJquery/CommonJquery.js";
import {
  initIndexedDB,
  retrieveDataFromObjectStore,
  other_charges_table_name,
  rupee_icon_table_name,
  customer_order_data_details,
  customer_order_data_name,
} from "../../LocalConnection/OfflineConnection.js";
const InvoicePos = () => {
  const { id, status } = useParams();
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const [ResturantData, setResturantData] = useState([]);
  const [getDataCustomer, setGetDataCustomer] = useState([]);
  const [getDataCustomerDetails, setGetDataCustomerDetails] = useState([]);
  const [RupessData, setRupessData] = useState("₹");

  useEffect(() => {
    if (status === "online") {
      const start_date = "";
      const end_date = "";
      const flag = "1";
      const call_id = id;
      master_data_get(start_date, end_date, flag, call_id, "");
    } else {
      const setDbCallback = async (result) => {
        const customer_order_data_name_data = await retrieveDataFromObjectStore(
          result,
          customer_order_data_name,
          id
        );
        const customer_order_data_details_data =
          await retrieveDataFromObjectStore(
            result,
            customer_order_data_details,
            id
          );
        const other_charges = await retrieveDataFromObjectStore(
          result,
          other_charges_table_name,
          ""
        );
        const rupee_icon = await retrieveDataFromObjectStore(
          result,
          rupee_icon_table_name,
          ""
        );
       
        setGetDataCustomer(
          customer_order_data_name_data.filter((item) => item.token === id)[0]
        );
        setGetDataCustomerDetails(
          customer_order_data_details_data.filter((item) => item.token === id)
        );
        setResturantData(other_charges);
        setRupessData(rupee_icon[0].rupees_icon);
      };

      // Call the initIndexedDB function and pass the callback
      initIndexedDB(setDbCallback);
    }
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("token_id", call_id);
    await server_post_data(get_invoice_data_single, fd)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setGetDataCustomer(Response.data.message.customer_data[0]);
          setGetDataCustomerDetails(
            Response.data.message.customer_data_details
          );

          setResturantData(Response.data.message.other_charges[0]);
          setRupessData(Response.data.message.rupees);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.error("Error in server_post_data:", error);
        setshowLoaderAdmin(false);
      });
  };

  const handlePrintInvoice = (ID_NAME) => {
    const printContent = document.querySelector("#" + ID_NAME);
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContent.outerHTML;
    window.print();
    document.body.innerHTML = originalContents;
  };

  return (
    <>
      <section className="my-5 summary">
        <div className="container">
          <div className="row ">
            <div className="col-12">
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4 col-md-4 box_width">
                  <input
                    type="button"
                    id="btn"
                    value="Print Invoice"
                    onClick={() => handlePrintInvoice("DivIdToPrint")}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="button"
                    id="btn"
                    value="Print Kot"
                    onClick={() => handlePrintInvoice("DivIdToPrint2")}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <a href="/">
                    <input type="button" id="btn" value="back to Home " />
                  </a>
                  <div className="row" id="printDiv">
                    <div className="col-12 warp" id="DivIdToPrint">
                      <p className=" show_header style_show_1">
                        <span className="style_show_2">
                          {ResturantData.restaurant_name}
                        </span>
                        <br />
                        <span>{ResturantData.address}</span>
                        <br />
                        <span>Phone No : {ResturantData.res_moblie_no}</span>
                      </p>
                      <div className="witdth_385px">
                        <b className="style_show_3">Date</b>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span className="style_show_4">
                          {getDataCustomer.pickup_date}
                        </span>
                      </div>
                      {getDataCustomer.invoice_id !== 0 && (
                        <div className="witdth_385px">
                          <b className="style_show_3">Bill No</b>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span className="style_show_4">
                            {getDataCustomer.invoice_id}
                          </span>
                        </div>
                      )}

                      {getDataCustomer.table_no !== " " && (
                        <div className="witdth_385px">
                          <b className="style_show_3">Table No</b>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span className="style_show_4">
                            {getDataCustomer.table_no}
                          </span>
                        </div>
                      )}
                      {getDataCustomer.address_users !== "" && (
                        <div className="witdth_385px">
                          <b className="style_show_3">Address</b>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span className="style_show_4">
                            {getDataCustomer.address_users}
                          </span>
                        </div>
                      )}

                      <h3 className="text-center style_show_5">&nbsp;</h3>

                      <div className="witdth_385px">
                        {getDataCustomerDetails.map((item, index) => (
                          <div key={index}>
                            <b className="h3_limit style_show_6">
                              {item.item_name} <br></br>
                            </b>
                            <span className=" style_show_4 left_width">
                              {item.item_qty}
                            </span>
                            <span className="style_show_4">
                              {RupessData} {item.item_price}
                            </span>
                          </div>
                        ))}
                      </div>

                      <h3 className="text-center style_show_7">&nbsp;</h3>

                      <table className="style_show_8">
                        <div className="witdth_385px">
                          <b className="style_show_3">Total Amount</b>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span className="style_show_4">
                            {RupessData} {getDataCustomer.amountpay}
                          </span>
                        </div>
                        <div className="style_show_9">
                          <b className="style_show_3">Net Amount</b>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span className="style_show_4">
                            {RupessData} {getDataCustomer.amountpay}{" "}
                          </span>
                        </div>
                      </table>
                      {/* <p className="tank style_show_10">www.Upresro.in</p> */}
                      <p className="tank style_show_11">
                        Thank You Visit Again
                      </p>
                    </div>

                    <div className="col-12 summary warp" id="DivIdToPrint2">
                      {getDataCustomer.invoice_id !== 0 && (
                        <p className="center">
                          Bill No : {getDataCustomer.invoice_id}
                        </p>
                      )}
                      {/* </hr> */}

                      <table className="text-left weight_600">
                        <div className="width_100_per">
                          {getDataCustomerDetails.map((item, index) => (
                            <div key={index}>
                              <b className="h3_limit style_show_12">
                                {item.item_name} <br></br>
                              </b>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <span className="style_show_4">
                                {item.item_qty}
                              </span>
                            </div>
                          ))}
                        </div>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-4"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InvoicePos;
